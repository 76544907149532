import { defineStore } from 'pinia'
import { ref } from 'vue'
import { usePasswordApi } from '@/api/password'
import { ElMessage } from 'element-plus'
import router from '@/router'

export const useAuthStore = defineStore('auth', () => {
  const authApi = usePasswordApi()
  const token = ref(localStorage.getItem('token') || '')
  const user = ref(null)
  const logoutTimer = ref(null)
  const TIMEOUT_DURATION = 1000 *5*60// 30秒

  const clearLogoutTimer = () => {
    if (logoutTimer.value) {
      clearTimeout(logoutTimer.value)
      logoutTimer.value = null
    }
  }

  const startLogoutTimer = () => {
    clearLogoutTimer()
    logoutTimer.value = setTimeout(async () => {
      await logout()
      ElMessage.warning('登录超时，请重新登录')
      router.push('/login')
    }, TIMEOUT_DURATION)
  }

  const isAuthenticated = () => {
    return !!token.value
  }

  const checkTokenStatus = async () => {
    const currentToken = localStorage.getItem('token')
    //console.log('当前token:', currentToken) // 调试日志
    
    if (!currentToken) return false
    
    try {
      await authApi.verifyToken()
      startLogoutTimer()
      return true
    } catch (error) {
      console.error('Token验证失败:', error)
      await logout()
      return false
    }
  }

  const login = async (credentials) => {
    try {
      token.value = ''
      localStorage.removeItem('token')
      
      //console.log('发送登录请求:', credentials)
      
      const response = await authApi.login(credentials)
      //console.log('登录响应:', response)
      
      // 修改这里：response 本身就是数据，不需要 .data
      const data = response
      
      //console.log('解析的数据:', data)
  
      if (!data || !data.token) {
        console.error('登录响应中没有token:', data)
        throw new Error('登录失败：未收到认证令牌')
      }
  
      // 设置新token
      const tokenWithBearer = data.token.startsWith('Bearer ') ? data.token : `Bearer ${data.token}`
      token.value = tokenWithBearer
      user.value = data.user
      localStorage.setItem('token', tokenWithBearer)
      
      //console.log('Token已设置:', tokenWithBearer)
      
      startLogoutTimer()
      return true
    } catch (error) {
      console.error('登录错误:', error)
      token.value = ''
      user.value = null
      localStorage.removeItem('token')
      throw error
    }
  }
  const logout = async () => {
    try {
      if (token.value) {
        await authApi.logout()
      }
    } finally {
      clearLogoutTimer()
      console.log( '登出成功');
      router.push('/login');
      token.value = ''
      user.value = null
      localStorage.removeItem('token')
    }
  }

  const register = async (userData) => {
    try {
      await authApi.register(userData)
      await login({
        username: userData.username,
        password: userData.password
      })
      return true
    } catch (error) {
      throw error
    }
  }
  const sendResetCode = async (email) => {
    try {
      const response = await authApi.sendResetCode({ email })
      return response
    } catch (error) {
      throw error
    }
  }
  const sendRegisterCode = async (email) => {
    try {
      const response = await authApi.sendRegisterCode({ email })
      return response
    } catch (error) {
      throw error
    }
  }
  
  const resetPassword = async ({ email, code, newPassword }) => {
    try {
      const response = await authApi.resetPassword({
        email,
        code,
        newPassword
      })
      return response
    } catch (error) {
      throw error
    }
  }

  const verifyResetCode = async ({ email, code }) => {
    try {
      const response = await authApi.verifyResetCode({ email, code })
      return response
    } catch (error) {
      throw error
    }
  }

  const verifyRegstCode = async ({ email, code }) => {
   
    try {
      const response = await authApi.verifyRegstCode({ email, code })
      return response
    } catch (error) {
      throw error
    }
  }

  return {
    token,
    user,
    login,
    logout,
    register,
    isAuthenticated,
    checkTokenStatus,
    sendResetCode,
    resetPassword,
    verifyResetCode,
    sendRegisterCode,
    verifyRegstCode 

  }
})
import request from './request'

export function usePasswordApi() {
  return {
    getAll() {
      return request.get('/passwords')
    },
    
    create(data) {
      return request.post('/passwords', data)
    },
    
    update(id, data) {
      
      return request.put(`/passwords/${id}`, data)
    },
    
    delete(id) {
      return request.delete(`/passwords/${id}`)
    },
    
    login(data) {
     // console.log('登录请求数据:', data);
      if (!data?.username || !data?.password) {
        return Promise.reject(new Error('用户名和密码不能为空'))
      }
      
      //console.log('发送请求到:', '/auth/login');
      
      return request.post('/auth/login', data)
        .then(response => {
        //  console.log('原始登录响应:', response);
          if (!response) {
            throw new Error('服务器返回空响应');
          }
          return response;
        })
        .catch(error => {
          console.error('API层错误详情:', error);
          if(error.response?.status === 401) {
            throw new Error('用户名或密码错误')
          }
          throw error;
        })
    },
    
    register(data) {
      if (!data?.username || !data?.password || !data?.email) {
        return Promise.reject(new Error('用户名、密码和邮箱不能为空'))
      }
      return request.post('/auth/register', data)
        .catch(error => {
          if (error.response?.status === 400) {
            throw new Error(error.response.data.error || '注册失败：用户名或邮箱已被使用')
          }
          throw new Error('注册失败：' + error.message)
        })
    },
    
    logout() {    
      return request.post('/auth/logout')
    
        .catch(error => {
          if(error.response?.status === 404) {
            console.warn('登出接口不存在，用户可能已登出')
            return Promise.resolve()
          }
          throw new Error('登出失败：' + error.message)
        })
    },
    
    sendResetCode(data) {
      if (!data?.email) {
        return Promise.reject(new Error('邮箱不能为空'))
      }
      return request.post('/auth/send-reset-code', data)
        .catch(error => {
          if (error.response?.status === 404) {
            throw new Error('该邮箱未注册')
          }
          throw new Error()
        })
    },


    
    resetPassword(data) {
      if (!data?.email || !data?.code || !data?.newPassword) {
        return Promise.reject(new Error('邮箱、验证码和新密码不能为空'))
      }
      return request.post('/auth/reset-password', data)
        .catch(error => {
          if (error.response?.status === 400) {
            throw new Error('验证码无效或已过期')
          }
          throw new Error('重置密码失败：' + error.message)
        })
    },
    sendRegisterCode (data) {
      if (!data?.email) {
        return Promise.reject(new Error('邮箱不能为空'))
      }
      return request.post('/auth/send-register-code', data)
        .catch(error => {
          if (error.response?.status === 404) {
            throw new Error
          }
          
        })
    },
   
    verifyRegstCode(data) {
      console.log(data);
      if (!data?.email || !data?.code) {
        return Promise.reject(new Error('邮箱和验证码不能为空'))
      }
      return request.post('/auth/verify-register-code', data)
        .catch(error => {
          if (error.response?.status === 400) {
            throw new Error('验证码无效或已过期')
          }
          throw new Error('验证失败：' + error.message)
        })
    },



    verifyResetCode(data) {
      console.log(data);
      if (!data?.email || !data?.code) {
        return Promise.reject(new Error('邮箱和验证码不能为空'))
      }
      return request.post('/auth/verify-reset-code', data)
        .catch(error => {
          if (error.response?.status === 400) {
            throw new Error('验证码无效或已过期')
          }
          throw new Error('验证失败：' + error.message)
        })
    }
  }
} 
import axios from 'axios'
import { ElMessage } from 'element-plus'
import env from '@/config/env'

const request = axios.create({
  baseURL: env.apiUrl,
  timeout: 5000
})

// 添加请求拦截器
request.interceptors.request.use(
  config => {
    // 从 localStorage 获取 token
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = token
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 响应拦截器
request.interceptors.response.use(
  response => response.data,
  error => {
    ElMessage.error(error.response?.data?.error || '请求失败')
    return Promise.reject(error)
  }
)

export default request 
const mode = import.meta.env.MODE 

const envConfig = {
  'development.local': {
    apiUrl: import.meta.env.VITE_LOCAL_DEV_API_URL,
    mode: import.meta.env.VITE_LOCAL_DEV_MODE,
    baseUrl: import.meta.env.VITE_LOCAL_DEV_BASE_URL
  },
  'development.remote': {
    apiUrl: import.meta.env.VITE_REMOTE_DEV_API_URL,
    mode: import.meta.env.VITE_REMOTE_DEV_MODE,
    baseUrl: import.meta.env.VITE_REMOTE_DEV_BASE_URL
  },
  'production.remote': {
    apiUrl: import.meta.env.VITE_REMOTE_PROD_API_URL,
    mode: import.meta.env.VITE_REMOTE_PROD_MODE,
    baseUrl: import.meta.env.VITE_REMOTE_PROD_BASE_URL
  },
  'production.node': {
    apiUrl: import.meta.env.VITE_NODE_PROD_API_URL,
    mode: import.meta.env.VITE_NODE_PROD_MODE,
    baseUrl: import.meta.env.VITE_NODE_PROD_BASE_URL
  }
}

export default envConfig[mode] || envConfig['development.local'] 